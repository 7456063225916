import { FC } from 'react'
import CN from 'classnames'

import { LeftNav } from 'components/atoms/LeftNav'

export interface SideBarProps {
  [x: string]: any
  isFullMenu?: boolean
  firstRoute?: any
  secondRoute?: any
  menuItemList?: any
  userDetails?: any
}

export const SideBar: FC<SideBarProps> = ({
  className,
  isFullMenu,
  firstRoute,
  secondRoute,
  menuItemList,
  userDetails,
  ...restProps
}: SideBarProps) => {
  const SideBarClasses = CN(
    'main-layout__sidebar z-[1] fixed left-0 h-full border-r border-r-N-300 transition-all duration-200 lg:h-full lg:top-0 bg-N-100 hidden lg:flex lg:w-[20%]',
    className,
  )
  return (
    <div className={SideBarClasses} {...restProps}>
      <div className='flex flex-col items-center w-full h-full px-[10px] py-2 bg-[#252529]'>
        <LeftNav
          menuItemList={menuItemList}
          firstRoute={firstRoute || ''}
          secondRoute={secondRoute}
          userDetails={userDetails}
        />
      </div>
      
    </div>
  )
}

SideBar.defaultProps = {
  onClick: undefined,
  menuItemList: undefined,
  firstRoute: undefined,
  secondRoute: undefined,
}

export default SideBar
