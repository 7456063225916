import { HeaderSection } from "components/molecules"
import AI from 'assets/images/AI.png'

export const Dashboard = () => {
  return (
    <div className='w-full'>
      <HeaderSection title="DASHBOARD" />

      <div className="grid grid-cols-1 md:grid-cols-3 p-10 gap-y-5 md:gap-x-[50px] md:gap-y-8 overflow-y-scroll md:overflow-visible md:h-auto h-[calc(100vh-80px)]">
        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-[14px]">NUMBER OF VIEWS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              132 560
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-[14px]">NUMBER OF LOGINS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              12 580 
            </div>  
          </div>
        </div>


        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-[14px]">NUMBER OF USERS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              650 202
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-start items-start bg-white rounded-[30px] p-4">
            <div className="flex justify-start items-center h-fit">
              <div className="w-[16px] h-[16px] flex bg-black rounded-full"></div>
              <div className="inter-bold font-bold pl-[14px] text-">NUMBER OF APPS</div>
            </div>

            <div className="h-full w-full flex justify-center items-center poppins-bold text-[38px] leading-tight">
              25 
            </div>  
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full lg:max-w-[240px] h-[240px] flex flex-col justify-center items-center bg-white rounded-[30px] p-4">
            <img src={AI} alt='AI' className='w-auto h-full'/>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Dashboard