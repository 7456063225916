import axios from "axios";
import { apiBaseUrl } from 'config/framework.config'


export const handleUploadImage = async (file: any, setImageUrl: any, notify:any, setIsLoading: any, navigate: any) => {
  setIsLoading(true)

  const formData = new FormData();

  formData.append('file', file);
  
  const token = localStorage.getItem('token')

  try {
    const response = await axios.post(`${apiBaseUrl}external-api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'authorization': 'Bearer ' + token,
      },
    });

    if (response.data.success) {
      setImageUrl(response.data.imageUrl);
      setIsLoading(false)
    } else {
      setIsLoading(false)

      if(response?.status===405 || response?.status===403 || response?.status===501 ){
        notify('Session expired.', 'error')
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
      }else{
        notify('Error uploading image', 'error')
      }
    }
  } catch (error: any) {
    setIsLoading(false)
    if(error?.response?.status===405 || error?.response?.status===403 || error?.response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
    }else{
      notify('Something went wrong.', 'error')
    } 
  }
};

